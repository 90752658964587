<template>
  <v-card id="account-setting-card">
    <v-card-title>Add new platform</v-card-title>
    <v-card-text>
      <v-form ref="addNewPlatformForm" v-model="valid" autocomplete="off" @submit.prevent="submitForm">
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="payload.domainPrefix"
              label="Company domain prefix"
              outlined
              dense
              maxlength="233"
              hide-details="auto"
              :rules="[validators.required, validators.alphaDashValidator]"
              :suffix="`${appEnv}.lightapply.com`"
              @input="onInput"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="payload.companyName"
              label="Company name"
              outlined
              dense
              maxlength="85"
              hide-details="auto"
              autocomplete="off"
              :rules="[validators.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="payload.recruitersPassword"
              label="Recruiters password"
              outlined
              dense
              maxlength="128"
              hide-details="auto"
              autocomplete="off"
              hint="Password for users: jann, karinas, pawelz"
              :rules="[validators.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-select
              v-model="payload.language"
              label="Language"
              :items="languageOptions"
              outlined
              dense
              hide-details="auto"
              :rules="[validators.required]"
            ></v-select>
          </v-col>
          <v-col cols="12" class="d-flex align-center justify-center">
            <v-btn
              x-large
              :disabled="!valid"
              elevation="0"
              color="primary"
              :loading="loading"
              class="ma-3"
              type="submit"
              >Add</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { ref } from '@vue/composition-api'

import { alphaDashValidator, required } from '@core/utils/validation'
import { useMutations } from 'vuex-composition-helpers'
import { axiosIns } from '@/plugins/axios'

export default {
  name: 'AddNewPlatform',
  setup() {
    const loading = ref(false)
    const valid = ref(false)
    const addNewPlatformForm = ref(null)

    const { SET_ERROR_SNACK, SET_SUCCESS_SNACK } = useMutations('app', ['SET_ERROR_SNACK', 'SET_SUCCESS_SNACK'])

    const languageOptions = [
      {
        text: 'Polski',
        value: 'pl',
      },
      {
        text: 'English',
        value: 'en',
      },
    ]

    const payload = ref({
      domainPrefix: '',
      companyName: '',
      recruitersPassword: '',
      language: 'pl',
    })

    const onInput = () => {
      payload.value.domainPrefix = payload.value?.domainPrefix?.replace(' ', '-').toLowerCase()
    }

    const submitForm = async () => {
      if (!valid) return
      if (payload.value.domainPrefix.includes(`${process.env.VUE_APP_ENV}.lightapply.com`)) return
      try {
        loading.value = true
        await axiosIns.post(`super-admin/add-new-platform/`, payload.value)
        SET_SUCCESS_SNACK('New platform added! 🎉')
        addNewPlatformForm.value.reset()
      } catch (e) {
        SET_ERROR_SNACK('Something went wrong. Please contact with Head of Magic 🧙')
      }
      loading.value = false
    }

    // const redirectToCandidatePanel = () => {
    //   if (process.env.VUE_APP_ENV === 'demo') {
    //     window.location.replace('https://app.demo.lightapply.com')
    //   } else if (process.env.VUE_APP_ENV === 'staging') {
    //     window.location.replace('https://app.staging.lightapply.com')
    //   } else {
    //     window.location.replace('https://app.lightapply.com')
    //   }
    // }

    return {
      onInput,
      submitForm,

      languageOptions,
      appEnv: process.env.VUE_APP_ENV,
      loading,
      valid,
      addNewPlatformForm,
      payload,

      validators: {
        required,
        alphaDashValidator,
      },
    }
  },
}
</script>

<style scoped></style>
